
// dropdown
.dropdown-toggle {
    &:after {
        display: none;        
    }
}
.account-pages {
    background-color: #f8f8fb;

}
.width-fit{
    width: fit-content;
}
.height-fit{
    height: fit-content;
}

.table-card-body{
    min-height: calc( 100vh - 220px);
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}

.avatar-title-font-xl{
    font-size: 12rem;
}
.avatar-title-bg-light{
    background-color: #eff2f7;
    color: #dbdee7
}
.bx-trash:hover{
   color: #f46a6a
}
.contact-links{
    cursor: pointer;
}

.status-check{
    text-align: center;
    line-height: 0;
    i{
        font-size: 20px;
    }
}

th[data="actions"]{
    width: 10%;
    &:before, &:after{
        content:''!important
    }
}
th[data="status"]{
    width: 6%;
    &:before, &:after{
        content:''!important
    }
}
.table-responsive{
    tr:hover{
        background-color: #e2e4ee3b;
    }
}
.text-dayim{
    background: -webkit-linear-gradient(298deg ,#ffc400, #ff8920);
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff00;
}
.border{
    &-base{
        border-style: solid;
        border-color: #ebebeb;
        border-top: 0;
        border-bottom-width: 2px;
        border-right: 0;
        border-left: 0;
        border-radius: 0;
    }
    &-roof{
        border-style: solid;
    border-color: #ebebeb;
    border-top-width: 2px;
    border-bottom: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    }
}
.bg{
    &-parent{
        background-color: #007DA8;
        color: #fff;
        font-size: 12px;
    }
    &-sub-category{
        background-color: #00A4B7;
        color: #fff;
        font-size: 12px;
    }
    &-make{
        background-color: #00C7A8;
        color: #fff;
        font-size: 12px;
    }
    &-model{
        background-color: #8DE588;
        color: #fff;
        font-size: 12px;
    }
}