/* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap/scss/bootstrap';

/* HTML Content */
html, .page-content{
  background-color: #f8f8fb;
}
.card{
  border-radius: 0.7rem!important;
}

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}
.scale-2{
  transform: scale(1.2);
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.required-asterisk{
  color: red;
  font-size: 1.2em;
}

ngx-intl-tel-input input {
  height: 36px;
  margin-bottom: 20px;
  padding: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  width: 216px!important;
  font-size: 0.8125rem;
}

ngx-intl-tel-input.is-invalid input {
	border: 1px solid #f46a6a;
}

ngx-intl-tel-input input:hover {
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
	outline: none !important;
	border-color: #3498db;
	box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
	background-color: #e5eaf1;
}

ng-select.is-invalid .ng-select-container {
border-color: #f46a6a !important;
//box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

ng-select.currency .ng-select-container {
  border: none !important;
  //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
  }

.btn-violet{
  background-color: rgb(133, 50, 199, 20%);
  color: #8532C7
}
.btn-green{
  background-color: rgb(34, 165, 133, 20%);
  color: #22A585
}
.dir-rtl{
  direction: rtl
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.theme-primary{
  margin-top: 10px;
  border-radius: 12px;
  .bs-datepicker-container{
    padding: 0;
    .bs-datepicker-head, .selected{
      background-color: var(--bs-primary)
    }
    .bs-datepicker-head{
      border-radius: 12px 12px 0 0;
    }
    .selected{
      border-radius: 6px
    }
    .bs-datepicker-body table td.is-highlighted{
      span{
        border-radius: 6px
      }
      &:not(.disabled):not(.selected){
        .selected{
          background-color: #455bcb
        }
      }
    }
  }
}
.caret-none{
  caret-color: transparent;
  cursor: pointer;
}
img.entered.lazyloaded{
  width: 100%
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
i{
  line-height: 1
}
.vertical-middle{
  vertical-align: middle;
}
.no-bg{
  background-color: transparent;
}
.cursor-pointer{
  cursor: pointer;
}
.text-h-white{
  &:hover{
    color: white!important;
  }
}
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
